import React, { createContext, useState, useEffect } from 'react';
//import Axios from 'axios';

export const UserContext = createContext();

function isBrowser() {
  return typeof window !== "undefined"
}

export default ({children}) => {
  const defaultUser = isBrowser() && window.sessionStorage.getItem("user")
    ? JSON.parse(window.sessionStorage.getItem("user"))
    : null;
  const defaultEmail = isBrowser() && window.sessionStorage.getItem("email")
    ? window.sessionStorage.getItem("email")
    : "";

  const [user, setUser] = useState(defaultUser);
  const [email, setEmail] = useState(defaultUser ? defaultUser.email : defaultEmail);

  const value = { 
      profile: user,
      email,
      isAuthenticated: () => {
        return user && user.id != 0;
      },
      login: (userData) => {
        if (!userData.name && userData.first_name)
          userData.name = userData.first_name + " " + userData.last_name;
          
        setUser(userData);
        setEmail(userData.email);

        window.sessionStorage.setItem("user", JSON.stringify(userData));
        window.sessionStorage.setItem("email", userData.email);
      },
      logout: () => {
        window.sessionStorage.clear();
        setUser(null);
      },
      rememberEmail: (emailAddress) => {
        setEmail(emailAddress);
        window.sessionStorage.setItem("email", emailAddress);
      }
    };
  
  /*
  useEffect(
    () => {
      let source = Axios.CancelToken.source();

      async function fetchCurrentUser() {
        try {
          const result = await Axios(
              settings.API_URL + 'user/current/data',
          );

          setEmployee(result.data);
          setIsAuthenticated(true);
        }
        catch (error) {
          if (!Axios.isCancel(error)) {
            throw error;
          }
        }
      }

      fetchCurrentUser();

      return () => {
        source.cancel();
      };
    }, 
    []
  );
  */

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}
