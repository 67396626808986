import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import { colors } from "../../settings"

const Button = styled.button`
    background-color: transparent;
    border: 1px solid ${props => props.color ? colors[props.color] : colors.blue};
    color: ${props => props.color ? colors[props.color] : colors.blue};
    padding: ${props => props.size && props.size == "small" ? "5px 10px" : "7px 20px"};
    font-size: ${props => props.size && props.size == "small" ? "12px" : "15px"};
    border-radius: 5px;
    margin: 2px;
    cursor: pointer;
    
    display: ${props => (props.center || props.wide)?'block':'inline-block'};
    width: ${props => props.wide ? '100%':'auto'};
    margin-left: ${props => props.center?'auto':'0px'};
    margin-right: ${props => props.center?'auto':'0px'};

    &:hover {
        background-color: ${props => props.color ? colors[props.color] : colors.blue} !important;
        color: ${props => props.color ? colors[props.color + "HoverText"] : "white"};
    }
    &[disabled] {
        border-color: #ccc !important;
        color: #ccc !important;
        cursor: not-allowed;
    }

    svg {
        margin-right: ${props => (props.label && (!props.iconPos || props.iconPos == "left")) ? 5:0}px;
        margin-left: ${props => (props.label && (props.iconPos && props.iconPos == "right")) ? 5:0}px;
    }
    .badge {
        background-color: red;
        color: white;
        font-size: 80%;
        border-radius: 5px;
        margin-left: 5px;
        display: inline-block;
        padding: 2px 4px;
    }
`

function HoverButton(props) {
    return (
        <Button type="button" {...props} title={props.tooltip || ""}>
            { ((props.icon || props.busy) && (!props.iconPos || props.iconPos == "left")) && <FontAwesomeSvgIcon icon={props.busy ? faSpinner : props.icon} spin={props.busy} />}
            <span>{props.label}</span>
            { ((props.icon || props.busy) && (props.iconPos && props.iconPos == "right")) && <FontAwesomeSvgIcon icon={props.busy ? faSpinner : props.icon} spin={props.busy} />}
            {props.badge &&
                <span className="badge">{props.badge}</span>
            }
        </Button>
    );
}

export default HoverButton