/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/global.css"

import "@fontsource/roboto/latin-300.css"
import "@fontsource/roboto/latin-400.css"
import "@fontsource/roboto/latin-500.css"

import "@fontsource/roboto-slab/latin-300.css"
import "@fontsource/roboto-slab/latin-400.css"
import "@fontsource/roboto-slab/latin-700.css"

export const onClientEntry = () => {
    var loader = document.getElementById('loader-wrapper');
    
    if (loader)
        loader.style.display = "none";
}
//export const onPreRouteUpdate = () => {
    // var loader = document.getElementById('loader-wrapper');
    
    // if (loader)
    //     loader.style.display = "none";
//}
export const onRouteUpdateDelayed = () => {
    var loader = document.getElementById('loader-wrapper');
    
    if (loader)
        loader.style.display = "block";
}
// export const onPrefetchPathname = () => {
//     console.log("onPrefetchPathname")
// }
export const onRouteUpdate = () => {
    var loader = document.getElementById('loader-wrapper')

    if (loader)
        loader.style.display = "none";
}