import React, { createContext, useState, useEffect } from 'react';

import { postData } from "../services/server"

export const CartContext = createContext();

function isBrowser() {
  return typeof window !== "undefined"
}

export default ({children}) => {
  const defaultCart = {
    id: 0,
    items: [],
    total: 0
  }

  let sessionCart = isBrowser() && window.sessionStorage.getItem("cart") ? JSON.parse(window.sessionStorage.getItem("cart")) : null;

  const [cart, setCart] = useState(sessionCart || defaultCart);

  function storeCart(cartData) {
    window.sessionStorage.setItem("cart", JSON.stringify(cartData));
    setCart(cartData);
  }

  const value = { 
    cart,

    haveCart: () => {
      return cart.items.length > 0;
    },
    saveCart: (cartData) => {
      storeCart(cartData);
    },
    deleteCart: () => {
      window.sessionStorage.setItem("cart", null)
      setCart(defaultCart);
    },
    addSignToCart: (signId, callback) => {
      postData("/api/cart/addsign", {
              id: signId,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              console.log("add error", error, status)
              callback(null)
          }
      );
    },
    addAccessoryToCart: (accessorySizeId, quantity, options, callback) => {
      postData("/api/cart/addaccessory", {
              id: accessorySizeId,              
              cart_id: cart.id,
              quantity,
              options
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );
    },
    removeFromCart: (item, callback) => {
      postData("/api/cart/remove", {
              type: item.type,
              typeId: item.id,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );
    },
    updateCartQuantity: (item, quantity, callback) => {
      postData("/api/cart/quantity", {
              type: item.type,
              typeId: item.id,
              quantity: quantity,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );
    },
    updateCartContact: (data, callback) => {
      postData("/api/cart/contact", {
              ...data,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );  
    },
    updateCartShipping: (data, callback) => {
      postData("/api/cart/shipping", {
              ...data,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );  
    },
    updateCartBilling: (data, callback) => {
      postData("/api/cart/billing", {
              ...data,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      );  
    },
    calcCartShipping: (state, zip, callback) => {
      postData("/api/cart/calcshipping", {
              state: state,
              zip: zip,
              cart_id: cart.id
          }, 
          (response) => {
              storeCart(response);
              callback(response);
          }, 
          (error, status) => {
              callback(null)
          }
      ); 
    },
    requestShippingPrice: (data, callback) => {
      postData("/api/cart/requestshipping", { 
          ...data,
          cart_id: cart.id
        },
        (response) => {
            storeCart(response);
            callback(response);
        }, 
        (error, status) => {
            callback(null)
        }
      );
    }
  };
  
  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  )
}
