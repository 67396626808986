import React, { useState } from "react"

import MenuButton from "./MenuButton"
import SiteSideMenu from "./SiteSideMenu"

const SideMenuButton = (props) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  function onSideMenu() {
    setSideMenuOpen(!sideMenuOpen);
  }

  return (
    <>
        <MenuButton 
            onClick={onSideMenu}
        />
        {sideMenuOpen && 
          <SiteSideMenu 
            open={true}
            onClose={() => setSideMenuOpen(false)}
          />
        }
    </>
  )
}

export default SideMenuButton