import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import Layout from "../../src/components/layout/Layout"
import MajorError from "../../src/components/MajorError"

export default function PageLayout({children, layout, props}) {
  return (    
    <ErrorBoundary FallbackComponent={(error) => <MajorError reload log={error} />}>    
    <Layout type={layout || "standard"} {...props}>
        {children}
    </Layout>
    </ErrorBoundary>
  );
}