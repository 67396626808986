import React, { useEffect, useState } from "react"
import styled from 'styled-components'

const Background = styled.div`
    position: fixed;
    left:0px;
    right:0px;
    bottom: 0px;
    top: 0px;
    z-index: 3000000000;
    background-color: ${props => props.simple ? "rgba(0,0,0,.5)" : props.dark ? "rgba(0,0,0,.7)" : "rgba(255,255,255,.7)"};
    backdrop-filter: ${props => props.simple ? "none" : "blur(10px)"};
    overscroll-behavior: contain;
    
    transition: opacity .7s;
    opacity: 0;

    &.open {
        opacity: 1;
    }
`

function Overlay(props) {
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            setOpen(true);
        }, 
        []
    );

    return (
      <Background 
        simple={props.simple} 
        dark={props.dark}
        onClick={props.onClick} 
        className={open ? "open":""} 
        style={props.style}
        />
    );
}

export default Overlay