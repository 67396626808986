import React from "react"
import { Link, navigate } from "gatsby"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faFacebook, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons"

import Container from "./Container"
import Layout4Columns from "./Layout4Columns"
import GoogleImage from "../GoogleImage"

import { colors, devices } from "../../settings"
import { isTablet } from "../../utilities/media-queries"

const FooterContent = styled.footer`
  padding: ${isTablet() ? 20:0}px;
  padding-top: 25px;
  background-color: #38393a;
  color: white;
  font-size: 14px;
  
  @media ${devices.tablet} {
    font-size: 100%;
    padding: 20px;
  }

  h3 {
    font-size: 22px;
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }

  a {
    color: white;
    text-decoration: none;
  }

  ul {
    list-style-type: none;
    margin 0px;
    padding: 0px;

    li {
      margin-bottom: 4px;
    }

    a:hover {
        text-decoration: underline;
    }
  }

  small {
    display: block;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 20px;
  }

  iframe {
    border: 0px;
  }
`
const SocialIcons = styled.div`
  margin-top: 20px;

  a {
    background-color: white;
    margin-right: 5px;
    border-radius: 4px;
    display: inline-block;
    padding: 5px;
  }

  svg {
    color: ${colors.orange};
  }
`
const Copyright = styled.div`
  margin-top: 30px;
  font-size: 75%;
  border-top: 1px solid #666;
  padding: 20px 0px;
  color: #ccc;

  @media ${devices.mobile} {
    margin-top: 0px;
  }

  a {
    display; inline-block;
    margin-right: 20px;
    color: #ccc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  div {
    margin: 10px 0px;
    color: #666;
  }
`
const RightGrid = styled.div`
  text-align: right;
  padding-top: 25px;

  @media ${devices.mobile} {
    text-align: center;
    padding: 50px 10px !important;
  }
`

const Footer = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <FooterContent>
        <Container>
          <Layout4Columns align="top">
            <div>
                <h3>SHOP</h3>
                <ul>
                    <li><Link to="/builder/?from=Footer">Build &amp; Price a Sign</Link></li>
                    <li><Link to="/templates/">Business Signs</Link></li>

                    <li><Link to="/products/">Sign Products</Link></li>
                    <li><Link to="/products/accessories/">Sign Accessories</Link></li>

                    <li><Link to="/sign-price-request/?from=Footer">Custom Sign Price</Link></li>
                </ul>
            </div>  
            <div>
                <h3>SUPPORT</h3>
                <ul>
                    <li><Link to="/support/frequently-asked-questions/">FAQ</Link></li>
                    <li><Link to="/support/permits/">Permits</Link></li>
                    <li><Link to="/support/resources/">Sign Resources</Link></li>      
                    <li><Link to="/support/shipping-policy/">Shipping Policy - FREE</Link></li>
                    <li><Link to="/warranty/">Warranty</Link></li>                         
                    <li><Link to="/account/">My Account</Link></li>
                    <li><Link to="/sitemap/">Sitemap</Link></li>
                    <li><Link to="/blog/">Blog</Link></li>
                    <li><Link to="/signencyclopedia/">Sign Encyclopedia</Link></li>
                </ul>
            </div>  
            <div>
                <h3>COMPANY</h3>
                <ul>
                    <li><Link to="/contact-us/">Contact Us</Link></li>
                    <li><Link to="/why/reviews/">Product Reviews</Link></li>            
                    <li className="secondary"><a href="tel:8008630107">(800) 863-0107</a></li>
                </ul>

                <SocialIcons>
                    <a target="_blank" href="https://www.facebook.com/Signmonkeycom" title="Facebook">
                        <FontAwesomeSvgIcon icon={faFacebook} size="2x" fixedWidth />
                    </a>                        
                    <a target="_blank" href="https://www.youtube.com/channel/UCy3LWcIv1U-xrOBo526sK1A/videos" title="YouTube">
                        <FontAwesomeSvgIcon icon={faYoutube} size="2x" fixedWidth />
                    </a>
                    <a target='_blank' href="https://www.instagram.com/signmonkey/" title="Instagram">
                        <FontAwesomeSvgIcon icon={faInstagram} size="2x" fixedWidth />
                    </a>
                </SocialIcons>
            </div>  
            <RightGrid>              
              <div className="ul">
                <GoogleImage src="ul-logo.png?v2" required lazy alt="UL Logo" style={{height: "35px"}} width={95} height={25} />
                <small>E226851</small>
              </div>

              <br />

              <div style={{position:"relative"}}>
                <div
                    ref={ref} 
                    className="trustpilot-widget"
                    data-locale="en-US" 
                    data-template-id="5419b732fbfb950b10de65e5" 
                    data-businessunit-id="5a31a4d4b894c90d84225a75" 
                    data-style-height="80px" 
                    data-style-width="100%" 
                    data-theme="dark"
                    data-stars="1,2,3,4,5"
                >
                    <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank" rel="noopener noreferrer">
                        Trustpilot
                    </a> 
                </div>  
                <div 
                  onClick={(e) => {
                    navigate("/why/reviews");
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{height:"80px", position:"absolute", top:"0px", width:"100%", cursor:"pointer"}}
                >
                  &nbsp;
                </div>
              </div>
            </RightGrid>  
          </Layout4Columns>
          <Copyright>
                <Link to="/terms/">
                    Terms Of Service
                </Link>
                <Link to="/privacy-policy/">
                    Privacy Policy
                </Link>   
                <Link to="/intellectual-property/">
                    Intellectual Property 
                </Link>   

                <div>
                    Copyright © {new Date().getFullYear()} Paris Inc. DBA SignMonkey.com. All rights Reserved
                </div>
          </Copyright>
        </Container>
    </FooterContent>
  )
}

export default Footer