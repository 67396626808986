import React from "react"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Box = styled.div`
    text-align: center;
    margin: 10px;

    .MuiCircularProgress-root {
    }
    
    label {
        margin-top: 15px;
        display: block;
        text-align: center;
        color: #ccc;
    }
`

function Loading(props) {
    return (
      <Box style={props.style} className="loading">
          <FontAwesomeSvgIcon icon={faSpinner} spin size="3x" style={{color: "#ccc"}}  />
          {props.label && <label>{props.label}</label>}
      </Box>
    );
}

export default Loading