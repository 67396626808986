import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faTimes } from "@fortawesome/free-solid-svg-icons"

const Agreement = styled.div`
    position: fixed;
    background-color: rgba(105, 105, 105, 0.9);
    color: white;
    padding: 15px;
    left: 10px;
    bottom: 10px;
    max-width: 400px;
    font-size: 15px;

    > div {
        text-align: center;
        margin: 8px;
    }

    a { color: white; }
    a:hover { text-decoration: underline; }
`
const CloseButton = styled.button`
    float: right;
    margin: 0px 0px 10px 10px;
    background-color: transparent;
    border: 0px;
    color: white;
`
const AgreeButton = styled.button`
    border: 0px;
    padding: 10px 25px;
    background-color: white;
    color: black;
    font-size: 15px;

    &:hover {
        opacity: .9;
    }
`

const CookieAgreement = (props) => {
    const [agreed, setAgreed] = useState(true);

    useEffect(
        () => {
          const storedAgreed = window.localStorage.getItem("cookie-agreement")

          if (!storedAgreed)
            setAgreed(false);
        }, 
        []
    ); 

    function onAgree() {
        setAgreed(true);
        window.localStorage.setItem("cookie-agreement", true);
    }

    return (
        !agreed ?
            <Agreement>
                <CloseButton onClick={onAgree}>
                    <FontAwesomeSvgIcon icon={faTimes} size="2x" />
                </CloseButton>

                This website or its third-party tools use cookies to understand how you use our site and to improve your experience. By closing 
                this banner, scrolling this page, clicking a link or continuing to browse otherwise, you are accepting the use of cookies. Learn 
                more about our <Link to="/privacy-policy/">privacy policy</Link>.

                <div>
                    <AgreeButton onClick={onAgree}>
                        AGREE
                    </AgreeButton>
                </div>
            </Agreement>
            :
            null
    )
}

export default CookieAgreement