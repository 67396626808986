import React, { useEffect } from "react"

import Dialog from "./layout/Dialog"

import { trackError } from "../services/session"

function MajorError(props) {
  useEffect(
    () => {
        if (props.log) {
          trackError(props.log)
        }
    }, 
    []
  );

  let actions = []

  if (props.reload) 
    actions.push({
      label: "Reload Page",
      color: "blue",
      onClick: () => window.location.reload()
    })
  else 
    actions.push({
      label: "Close",
      color: "blue",
      onClick: props.onClose
    })

  

  return (
      <Dialog
        title="Something Went Wrong"
        actions={actions}
        onClose={props.onClose}
      >
        <p>
            {props.error || "An error has occurred."}
        </p>
        <p>
            Please give us a call at (800) 863-0107.
        </p>
      </Dialog>
  );
}

export default MajorError