export const colors = {
    white: "white",
    whiteText: "black",
    
    green: "#5cb85c",
    greenHover: "#5cb85c",
    greenLight: "#e3ffe3",
    greenText: "white",
    greenHoverText: "white",

    blue: "#007FFF",
    blueHover: "#026dd8",
    blueText: "white",
    blueHoverText: "white",

    orange: "#ec9715",
    orangeHover: "#de7b1b",
    orangeGradient: "-webkit-gradient(linear,left top,left bottom,from(#f38922),to(#ec441a))",
    orangeGradientText: "white",
    orangeTransparent: "rgba(245, 119, 24, .9)",
    orangeText: "white",
    orangeHoverText: "white",
    lightOrange: "#fdeacd",
    darkOrange: "#c37d13",

    yellow: "#ffcc13",
    yellowText: "black",

    purple: "#8b0cda",
    purpleGradient: "-webkit-gradient(linear, left top, left bottom, from(#8b0cda), to(#5602a0))",
    purpleBorder: "#5602a0",
    purpleText: "white",
    purpleHoverText: "white",
    lightPurple: "#9c86ff",

    grey: "#d5d5d5",
    greyHover: "#ccc",
    greyText: "black",
    greyHoverText: "black",

    panelBackground: "white",
    panelBackgroundTransparent: "rgba(255,255,255,.4)",
    panelText: "black",    
    panelAccent: "#d5d5d5",
    panelHover: "#eee",

    shadow: "1px 1px 4px #333",
    topShadow: "-1px -1px 4px #333",
    leftShadow: "-1px 1px 4px #333",

    darkGrey: "#444",
    darkGreyAccent: "#333",
    darkGreyHover: "#555",
    darkGreyText: "white",
    darkGreyHoverText: "white",

    lightGrey: "#efefef",
    headerText: "#333",
    border: "#eee",
    link: "#007FFF",
    
    red: "#dc3545",
    redText: "white",
    redHoverText: "white"
}

export const storage = {
    url: "https://storage.googleapis.com/signmonkey-148101.appspot.com/",
    images: "https://storage.googleapis.com/signmonkey-148101.appspot.com/images/"
}

export const google = {
    googleMapsKey: "AIzaSyDQq2Iz71gEA5gXb6HC3mkiBGnB0TvqkKE"
}

export const phones = {
    sales: "(800) 863-0107",
    production: "(800) 863-0107"
}

export const hours = {
    openHour: 8.5,
    closeHour: 17,
    isOpenNow: function() {
        return true;
    }
}

export const shipping = {
    productionDays: 5,
    // holidays: [
    //     new Date(2021, 10, 25), // Thanksgiving
    //     new Date(2021, 10, 26), // Black Friday
    //     new Date(2021, 11, 24), // Christmas Eve
    //     new Date(2021, 11, 25), // Christmas
    //     new Date(2021, 11, 27), // Shut Down
    //     new Date(2021, 11, 28), // Shut Down
    //     new Date(2021, 11, 29), // Shut Down
    //     new Date(2021, 11, 30), // Shut Down
    //     new Date(2021, 11, 31), // Shut Down
    //     new Date(2022, 0, 1), // New Years
    // ]
}

export const files = {
    MAX_UPLOAD_SIZE: 20*1024*1024 // 20MB
}

export const sizes = {
    mobile: '430px',
    tablet: '835px',
    laptop: '1250px',
    desktop: '2560px'
}

export const devices = {
    mobile: `(max-width: ${sizes.mobile})`,
    tablet: `(max-width: ${sizes.tablet})`,
    laptop: `(max-width: ${sizes.laptop})`,
    desktop: `(max-width: ${sizes.desktop})`
}

export const stacking = {
    panels: 100
}

export const social = {
    facebookPageUrl: 'https://www.facebook.com/Signmonkeycom',
    instagramPageUrl: 'https://www.instagram.com/signmonkey/'
}

export const reviews = {
    rating: 4.9,
    count: 256
}

export const photoCategories = [
    "Raceway Mount Signs",
    "Halo Lit Signs",
    "Channel Letters",
    "Reverse Channel Letters",
    "Cloud Signs",
    "Face Lit Letters",
    "Sign Cabinets",
    "Flag Signs",
    "Store Front Signs",
    "Face Lit & Halo Lit",
    "Day Night"
  ];