import Axios from 'axios';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

export function getSessionId() {
    if (window.localStorage.getItem("sessionid") === null) {
        window.localStorage.setItem("sessionid", uuidv4());
    }

    return window.localStorage.getItem("sessionid");
}

export function resetSessionId() {
    window.localStorage.setItem("sessionid", uuidv4());
}

export function trackPageView(page) {
    if (!window || !window.localStorage) {
        console.log("window.localStorage not found!");
        return;
    }

    if (window.localStorage.getItem("sessionid") === null) {
        window.localStorage.setItem("sessionid", uuidv4());
    }

    const sessionid = window.localStorage.getItem("sessionid");
    
    const userData = window.sessionStorage.getItem("user")
    const user = userData ? JSON.parse(userData) : null;
    
    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${user ? user.token : null}`,
            'X-Request-ID': getSessionId()
        },
        responseType: 'json'
     };

    let params = new URLSearchParams();

    params.append("page", page);
    params.append("url", window.location.href);
    params.append("sessionid", sessionid);

    Axios.post(process.env.GATSBY_API_BASE_URL + "/pageview", params, config);
}

export function trackError(error) {
    const sessionid = getSessionId();
    
    const userData = window.sessionStorage.getItem("user")
    const cartData = window.sessionStorage.getItem("cart")
    const user = userData ? JSON.parse(userData) : null;
    const cart = cartData ? JSON.parse(cartData) : null;
    
    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${user ? user.token : null}`,
            'X-Request-ID': getSessionId()
        },
        responseType: 'json'
     };

    let params = new URLSearchParams();

    params.append("error", JSON.stringify(error));
    params.append("url", window.location.href);

    if (user && user.id)
        params.append("user_id", user.id );

    params.append("email", window.sessionStorage.getItem("email"));

    if (cart && cart.id)
        params.append("cart_id", cart.id );
        
    params.append("sessionid", sessionid);

    Axios.post(process.env.GATSBY_API_BASE_URL + "/api/errors/log", params, config);
}
