import React, { useState, useEffect } from "react"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components";

import Overlay from "../Overlay";

import tabImage from "../../images/reviewstab.jpg"

const Drawer = styled.div`
    position: fixed;
    top: 0px;
    right: -460px;
    bottom: 0px;    
    background-color: white;
    z-index: 3000000000;
    border-left: 1px solid #ccc;
    width: 400px;
    transition: right 1s;

    &.tease {
        right: -400px;
    }
    &.open {
        right: 0px;
    }    
    &.closed {
        right: -500px;
    }

    div.header {
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        text-align: right;
        padding: 5px;

        svg {
            cursor: pointer;
        }
    }

    div.content {
        padding: 10px;
    }    
`
const Tab = styled.div`
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 4px;
    position: absolute;
    left: -60px;
    top: 35%;
    background-color: white;
    border: 1px solid #ccc;
    border-right: 0px;
    text-align: center;
    width: 60px;
    height: 172px;
    cursor: pointer;

    small {
        display: block;
        margin-top: -5px;
        &:hover {
            text-decoration: underline;
        }
    }
`

const TrustPilotDrawer = (props) => {
    const [open, setOpen] = useState(null);
    const [height, setHeight] = useState(0);

    useEffect(
        () => {
            const headerHeight = 60;

            setHeight(window.innerHeight - headerHeight);

            setTimeout(() => {
                setOpen(false);
            }, 2000)
        }, 
        []
    );

    return (
        <>
            {open && 
                <Overlay 
                    simple
                    onClick={() => setOpen(false)} 
                />        
            }
            <Drawer 
                className={open == null ? "" : open == undefined ? "closed" : open ? "open" : "tease"}
                onClose={() => setOpen(false)}
            >   
                <div className="header">
                    <FontAwesomeSvgIcon icon={faTimes} onClick={() => setOpen(false)} size="2x" />
                </div>

                <Tab onClick={() => setOpen(true)}>
                    <img src={tabImage} alt="5 Star Reviews" width={50} />

                    <small onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setOpen(undefined);
                    }}>
                        Close
                    </small>
                </Tab>

                <div className="content">
                    <div 
                        class="trustpilot-widget" 
                        data-locale="en-US" 
                        data-template-id="539ad60defb9600b94d7df2c" 
                        data-businessunit-id="5a31a4d4b894c90d84225a75" 
                        data-style-height={height + "px"}
                        data-style-width="100%" 
                        data-theme="light" 
                        data-stars="1,2,3,4,5" 
                        data-review-languages="en"
                    >
                        <a href="https://www.trustpilot.com/review/signmonkey.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                </div>
            </Drawer>
        </>        
    )
}

export default TrustPilotDrawer