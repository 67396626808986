import Axios from 'axios';

import { getSessionId } from "./session"

export function postData(url, data, onSuccess, onError, onBoth) {
    const userData = window.sessionStorage.getItem("user")
    const user = userData ? JSON.parse(userData) : null;
    
    const config = {
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${user ? user.token : null}`,
            'X-Request-ID': getSessionId()
        },
        responseType: 'json'
     };

    let params = new URLSearchParams();

    for (const key of Object.keys(data)) {
        params.append(key, data[key]);
    }

    Axios.post(process.env.GATSBY_API_BASE_URL + url, params, config)
      .then(function (response) {
        if (onSuccess)
            onSuccess(response.data);
        if (onBoth) 
            onBoth();
      })
      .catch(function (error) {
        console.log("error status", error.response ? error.response.status : -1);
        if (onError)
            onError(error.message, error.response ? error.response.status : -1, error.response);
        if (onBoth) 
            onBoth();          
      });
}

export function uploadImage(url, image, thumbnail, preview, onProgress, onSuccess, onError, onBoth) {
    var data = new FormData();

    if (image.files)
        data.append('image', image.files[0]);
    else 
        data.append('image', JSON.stringify(image)); // manual image props (used for background where we don't want to upload the original image)
    if (thumbnail)
        data.append('thumbnailData', thumbnail);
    if (preview)
        data.append('previewData', preview);

    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        if (onProgress)
            onProgress(percentCompleted);
      }
    };

    Axios.post(url.indexOf("://") === -1 ? process.env.GATSBY_API_BASE_URL + url : url, data, config)
        .then(function (response) {
            if (response.data.error && response.data.error.length > 0)
                onError(response.data.error);
            else if (onSuccess)
                onSuccess(response.data);
            if (onBoth) 
                onBoth();
        })
        .catch(function (error) {
            console.log("uploadImage error", error);
            if (onError)
                onError(error.message);
            if (onBoth) 
                onBoth();
        });
    
}

export function uploadFile(url, paramName, fileName, fileData, onProgress, onSuccess, onError, onBoth) {
    var data = new FormData();

    data.append(paramName, fileData);

    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        if (onProgress)
            onProgress(percentCompleted);
      }
    };

    Axios.post(url.indexOf("://") === -1 ? process.env.GATSBY_API_BASE_URL + url : url, data, config)
        .then(function (response) {
            if (response.data.error && response.data.error.length > 0)
                onError(response.data.error);
            else if (onSuccess)
                onSuccess(response.data);
            if (onBoth) 
                onBoth();
        })
        .catch(function (error) {
            if (onError)
                onError(error.message);
            if (onBoth) 
                onBoth();
        });
    
}

export function getData(url, onSuccess, onError, onBoth) {
     const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'json'
     };

    Axios.get(process.env.API_BASE_URL + url, config)
      .then(function (response) {
        if (onSuccess)
            onSuccess(response.data);
        if (onBoth) 
            onBoth();
      })
      .catch(function (error) {
        console.log("error status", error.response ? error.response.status : -1);
        if (onError)
            onError(error.message, error.response ? error.response.status : -1);
        if (onBoth) 
            onBoth();
      });
}