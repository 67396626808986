import React from "react"
import { Img } from 'react-image'

import { storage } from '../settings'
import notfound from '../images/nothumbnail.jpg'

function GoogleImage(props) {
    const isDataUrl = props.src && props.src.substring(0, 5) == "data:";

    const url = isDataUrl ? props.src : props.root ? storage.url + props.src : storage.images + props.src;

    return (
      props.required ? 
        <img 
          src={url} 
          alt={props.alt} 
          className={props.className}
          style={Object.assign({maxWidth: "100%"}, props.style)}     
          width={props.width}    
          height={props.height}
          loading={props.lazy ? "lazy":"eager"}
        />
      :
        <Img 
          src={[
            url,
            notfound
          ]} 
          alt={props.alt} 
          className={props.className}
          style={Object.assign({maxWidth: "100%"}, props.style)}     
          width={props.width}    
          height={props.height}
          loading={props.lazy ? "lazy":"eager"}
        />
    );
}

export default GoogleImage