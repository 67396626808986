import React from "react"

const HeadsetIcon = (props) => {
  return (
    <svg 
        width="100%" 
        height="100%" 
        viewBox="0 0 20 20" 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg"
        style={{
            fillRule:"evenodd",
            clipRule:"evenodd",
            strokeLineJoin:"round",
            strokeMiterLimit:"2"
        }}
    >
    <g>
        <path 
        d="M11.77,17.842C11.638,17.389 11.22,17.058 10.725,17.058C10.124,17.058 9.636,17.545 9.636,18.146C9.636,18.747 10.124,19.234 10.725,19.234C11.178,19.234 11.567,18.957 11.731,18.562L14.167,18.562C15.464,18.696 16.729,17.706 16.777,16.359L16.777,14.754C17.046,14.753 17.264,14.752 17.285,14.75C18.674,14.611 19.773,12.821 19.773,10.634C19.773,8.631 18.852,6.961 17.63,6.587C17.339,5.343 16.809,4.157 16.058,3.098C13.864,-0.132 9.452,-0.974 6.211,1.227C5.646,1.611 5.14,2.073 4.708,2.601C3.693,3.81 3.069,5.107 2.75,6.519C2.572,6.52 2.445,6.522 2.425,6.525C1.066,6.71 0,8.479 0,10.634C0,12.821 1.099,14.611 2.488,14.75C2.545,14.755 4.064,14.757 4.099,14.754C4.784,14.699 5.323,14.126 5.323,13.427L5.323,7.84C5.323,7.185 4.849,6.64 4.226,6.53C4.214,6.528 4.168,6.526 4.097,6.524C4.411,5.41 4.964,4.372 5.73,3.438C8.234,0.378 12.76,0.728 14.981,3.862C15.558,4.675 15.984,5.574 16.25,6.519C15.893,6.52 15.579,6.524 15.547,6.53C14.924,6.64 14.45,7.185 14.45,7.84L14.45,13.427C14.45,14.126 14.989,14.699 15.674,14.754C15.685,14.755 15.843,14.755 16.057,14.755L16.057,16.333C15.98,17.332 15.147,17.895 14.207,17.842L11.77,17.842Z" 
        style={{
            fill:props.color || "rgb(236,151,21)"
        }}/>
    </g>
    </svg>
  )
}

export default HeadsetIcon