import React from 'react';

import UserContext from "../../src/state/UserContext"
import CartContext from '../../src/state/CartContext';

export default function TopLayout({children}) {

  return (
    <React.Fragment>
        <>
        <UserContext>
          <CartContext>
            {children}
          </CartContext>
        </UserContext>
        </>
    </React.Fragment>
  );
}