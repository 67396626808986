/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { BrowserView } from "react-device-detect";
import styled from "styled-components"

import Header from "./Header"
import Footer from "./Footer"
import CookieAgreement from "../CookieAgreement"
import TrustPilotDrawer from "../drawers/TrustPilotDrawer"

import { devices, colors } from "../../settings"

const AttentionBar = styled.div`
  background-color: ${colors.blue};
  color: white;
  padding: 5px;
  text-align: center;
  position: relative;
  top: -5px;


  @media ${devices.mobile} {
    span {
      display: none;
    }
  }
`
const AttentionBar2 = styled.div`
  background-color: #a7a8a9;
  color: white;
  padding: 5px;
  text-align: center;
  position: relative;
  top: -5px;  
`

const Layout = ({ type, section, children, ...props }) => {
  return (
    <>
      {(type != "build" && type != "print" && type != "unsubscribe") && 
        <>
          <Header checkout={type == "checkout"} type={type} {...props} />
          {false && <TrustPilotDrawer />}
        </>
      }
      {false && 
        <AttentionBar>
          We currently are experiencing an internet outage, responding to calls/emails will be delayed.
        </AttentionBar>
      }
      {false && 
        <AttentionBar2>
          Due to high volume of sales, our build time may exceed our 5 day target, please call to get accurate ship date.
        </AttentionBar2>
      }
      <main>
        {children}
      </main>
      {type != "build" && type != "print" && type != "unsubscribe" && <Footer />}
      {type != "build" && type != "print" && type != "unsubscribe" && <CookieAgreement />}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout