import React from "react"
import styled from 'styled-components'

import { devices } from "../../settings"

const ContainerBox = styled.div`
   max-width: ${props => props.mode === "full" ? "100%": (props.width || 1250)+"px"};
   width: 100%;
   margin-left: auto;
   margin-right: auto;

   @media ${devices.mobile} {
    max-width: 100vw;
    width: 100%;
   }   
`

const Container = (props) => (
  <ContainerBox mode={props.mode || "normal"} width={props.width} style={props.style}>
    { props.children }
  </ContainerBox>
)

export default Container