import React from "react"
import styled from 'styled-components'

import CloseButton from "../buttons/CloseButton"
import Loading from "../Loading";
import Overlay from "../Overlay";
import Button from "../buttons/HoverButton"

import { devices } from "../../settings"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

const Dialog = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    background-color: white;
    z-index: 3000000001;
    border: 1px solid #eee;
    box-shadow: 2px 2px 6px #999;
    min-width: 350px;
    min-height: 250px;
    max-width: 80vw;
    max-height: 80vh;
    border-radius: 8px;

    > div.header {
        display: flex;
        padding: 15px;
        border-bottom: 1px solid #ccc;
        align-items: center;        
        justify-content: space-between;
        background-color: #eee;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        h3 {
            margin: 0px;
        }
    }
    > div.content {
        padding: 10px;
        max-height: calc(100vh - 75px);
        overflow: auto;
    }
    > div.actions {
        position: absolute;
        z-index: 99;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #efefef;
        padding: 10px;
        border-top: 1px solid #ccc;
        display: flex;
        align-items: center;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        > * {
            margin-right: 5px;
        }
    }
`

export default (props) => {
  return (
      <>
        <Overlay 
            simple
            onClick={props.onClose} 
        />
        <Dialog style={props.style}>
            <div className="header">
                <h3>
                    {props.icon && <FontAwesomeSvgIcon icon={props.icon} style={{marginRight: "10px"}} />}
                    {props.title}
                </h3>
                <CloseButton onClick={props.onClose} />
            </div>
            <div className="content">
                {props.loading ?
                    <Loading />
                :
                    props.children
                }
            </div>
            {props.actions && 
                <div className="actions">
                    {props.actions.map((action, index) => (
                        <Button 
                            key={index}
                            label={action.label}
                            icon={action.icon}
                            color={action.color}
                            onClick={action.onClick}
                        />
                    ))}
                </div>
            }
        </Dialog>
      </>
    )
}