import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

import { faTimes } from "@fortawesome/free-solid-svg-icons"

const Button = styled.button`
    background-color: white;
    border: 0px;
    box-shadow: none;
    padding: 6px;
    color: black;
    font-size: 18px;

    &:hover {
        background-color: #eee;
    }
`

export default (props) => {
    return (
      <Button onClick={props.onClick}>
        <FontAwesomeSvgIcon icon={faTimes} />
      </Button>
    );
}
