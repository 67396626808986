import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

import { colors, devices } from "../../settings"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const ButtonContainer = styled.button`
  background-color: ${props => colors[props.color]};
  border: 0px;
  color: ${props => colors[props.color + "Text"]};
  font-size: ${props => props.size === "large" ? 20 : props.size === "huge" ? 25 : 15}px;
  border-radius: ${props => props.square ? 1:5}px;
  padding: ${props => props.size === "large" ? 15 : props.size === "huge" ? 20 : 10}px;
  margin: 1px;
  display: ${props => props.wide ? 'block':'inline-block'};

  width: ${props => props.wide ? '100%':'auto'};
  margin-left: ${props => props.center?'auto':'0px'};
  margin-right: ${props => props.center?'auto':'0px'};

  &[disabled] {
      opacity: .8;
      cursor: not-allowed;
  }
  &:hover {
      background-color: ${props => colors[props.color + "Hover"]};
  }

  svg {
      margin-right: ${props => props.label ? 5:0}px;
  }

  span.label {
    display: inline-block;

    @media ${devices.mobile} {
      display: none;
    }
  }
  span.mobile {
    display: none;

    @media ${devices.mobile} {
      display: inline-block;
    }
  }
`

function Button(props) {
    return (
      <ButtonContainer 
        type={props.type || "button"} 
        color={props.color || "blue"}
        size={props.size || "normal"}
        disabled={props.disabled || props.busy} 
        onClick={(e) => {
          if (props.to) {
            window.location = props.to;
          }

          if (props.onClick)
            props.onClick(e);
        }} 
        label={props.label} 
        wide={props.wide} 
        center={props.center}
        square={props.square}
        style={props.style}
      >
        { (props.icon || props.busy) && <FontAwesomeSvgIcon icon={props.busy ? faSpinner : props.icon} spin={props.busy} />}
        <span className={props.labelMobile ? "label":""} dangerouslySetInnerHTML={{__html: props.label}}/>
        {props.labelMobile && <span className="mobile">{props.labelMobile}</span>}
        { (props.rightIcon) && <FontAwesomeSvgIcon icon={props.busy ? faSpinner : props.rightIcon} spin={props.busy} style={{marginLeft: "10px"}} />}
      </ButtonContainer>
    );
}

export default Button