import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faStar, faUser, faShapes, faInfo, faTimes, faPlus, faWrench, faHome } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from 'react-device-detect'

import { UserContext } from "../../state/UserContext"
import Overlay from "../Overlay"
import HeadsetIcon from "../icons/HeadsetIcon"
import { colors } from "../../settings"

const Menu = styled.div`
    background-color: #e8e8e8;
    position: fixed;
    top: 0px;
    bottom: 0px;
    width: 300px;
    z-index: 3000000001;

    transition-property: left;
    transition-duration: .5s;
    left: ${props => props.open ? "0px": "-300px"};
  
    h1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin: 0px;
        color: white;
        font-size: 15px;
        font-weight: 500;
        background-color: #9c86ff;
        border-bottom: 1px solid black;

        a {
            color: white;
        }
        > div {
            display: flex;

            svg {
                width: 35px;
                height: 35px;
                margin-top: 6px;
                margin-right: 8px;
            }

            div:nth-child(1) {
                opacity: .7;
            }
        }
    }

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px 0px 25px 0px;

        li {
            background-color: white;
            border-bottom: 1px solid #ccc;
            
            &:hover {
                background-color: #eee;
            }

            a {
                display: flex;
                align-items: center;
                padding: 15px 20px;
                color: black;
                font-weight: 300;

                span {
                    flex: 1;
                }

                img {
                    width: 16px;
                    margin-right: 8px;
                }
                svg {
                    margin-right: 8px;
                }

                &.red {
                    color: ${colors.red};
                    font-weight: 500;
                }
            }
        }
    }

`

const SiteSideMenu = (props) => {
  const userContext = useContext(UserContext);
  const [cloning, setCloning] = useState(false);

  return (
      <>
        <Overlay
            simple
            onClick={props.onClose}
        />
        <Menu open={props.open}>
            <h1>
                <div>
                    <HeadsetIcon color="white" />
                    <a href="tel:8008630107">
                        <div>TALK TO A SIGN EXPERT</div>
                        <div>(800) 863-01073</div>
                    </a>
                </div>
                
                { isMobile &&
                    <FontAwesomeSvgIcon 
                        icon={faTimes} 
                        size="2x" 
                        onClick={props.onClose}
                    />
                }
            </h1>
            <ul>
                <li>
                    <Link to="/" onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faHome} fixedWidth />
                        <span>Home</span>
                    </Link>
                </li>                
                <li>
                    <Link to="/products/" onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faShapes} fixedWidth />
                        <span>Products</span>
                    </Link>
                </li>
                <li>
                    <Link to="/builder/?from=SiteMenu" onClick={props.onClose} className="red">
                        <FontAwesomeSvgIcon icon={faPlus} fixedWidth />
                        <span>Design &amp; Price Sign</span>
                    </Link>
                </li>
                <li>
                    <Link to="/templates/" onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faStar} fixedWidth />
                        <span>Design Templates</span>
                    </Link>
                </li>
                <li>
                    <Link to="/why/" onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faInfo} fixedWidth />
                        <span>Why SignMonkey?</span>
                    </Link>
                </li>
                <li>
                    <Link to="/support/" onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faWrench} fixedWidth />
                        <span>Support</span>
                    </Link>
                </li>
                <li>
                    <Link to={userContext.isAuthenticated() ? "/account/signs/":"/account/"} onClick={props.onClose}>
                        <FontAwesomeSvgIcon icon={faUser} fixedWidth />
                        <span>Your Account</span>
                    </Link>
                </li>
            </ul>      
        </Menu>
    </>
  )
}

export default SiteSideMenu