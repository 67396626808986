/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import TopLayout from './TopLayout';
import PageLayout from './PageLayout';

export const wrapRootElement = ({ element, ...props }) => {
  return <TopLayout {...props}>{element}</TopLayout>;
};

export const wrapPageElement = ({ element, ...props }) => {
  return <PageLayout layout={element.props.pageContext.layout} {...props}>{element}</PageLayout>;
};
