import React from "react"
import styled from 'styled-components'

import { colors, devices } from "../../settings"

const Button = styled.button`
  margin: -5px 10px -5px -5px;
  padding: 8px 15px;
  background-color: white;
  border: 0px;
  border-right: 1px solid ${colors.lightGrey};
  text-align: center;

  @media ${devices.mobile} {
    padding: 5px 7px !important;
  }

  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const MenuButton = (props) => {
  return (
    <Button onClick={props.onClick} className="menu">
        <svg viewBox="0 0 100 80" width="30" height="30">
            <rect x="5" y="0"  width="90" height="12" rx="4"></rect>
            <rect x="5" y="30" width="90" height="12" rx="4"></rect>
            <rect x="5" y="60" width="90" height="12" rx="4"></rect>
        </svg>
        <div><small>{props.open ? "Close":"Menu"}</small></div>
    </Button>
  )
}

export default MenuButton